<template>
  <UiPageHeader
    v-if="props.initialData"
    :base-url="localePath()"
    class="h-0"
    header-class="app-header"
    @hamburger-click="toggleAppNav()"
    @change-height="(event) => setHeaderState('headerHeight', event)"
    @change-sticky="(event) => setHeaderState('isSticky', event)"
  >
    <template #icons>
      <component
        :is="isEcommerceEnabled ? 'CustomerHeaderIcon' : null"
        class="lg:hidden"
      />
      <component
        :is="isEcommerceEnabled ? 'AppHeaderCartIcon' : null"
        class="hidden lg:flex lg:flex-col lg:gap-1"
      />
    </template>
    <template #notifications>
      <AppSystemNotifications />
    </template>
    <template #headerTopLinks>
      <UiHeaderTopLinks
        class="hidden text-white lg:flex"
        :class="{ 'h-8': header?.isSticky, 'h-10': !header?.isSticky }"
      >
        <template #left>
          <button
            data-test="app-header-region-button"
            @click.prevent="toggleAppRegions"
          >
            {{ t("region") }}
          </button>
        </template>

        <template #right>
          <component :is="isEcommerceEnabled && props.initialData?.loginData ? 'CustomerHeader' : null" />
        </template>
      </UiHeaderTopLinks>
    </template>

    <template #region>
      <UiIcon
        name="globe"
        class="text-white"
        @click.prevent="toggleAppRegions"
      />
    </template>
    <template #search>
      <component
        :is="isEcommerceEnabled ? 'AppSearch' : null"
        v-model:search-phrase="searchPhraseValue"
      />
    </template>
    <template #headerBottomLinks>
      <UiHeaderBottomLinks
        :links="navigation"
        class="hidden lg:block"
        :compact-mode="header?.isSticky"
        @navigate="handleNavigateTo"
      >
        <UiUserRoleSelect
          v-if="showUserRoleSelect"
          :options="roleOptions"
          :label="t('cms_browse_as')"
          :selected-role="selectedRole"
          @change-option="changeOption"
        />
      </UiHeaderBottomLinks>
      <UiUserRoleSelect
        v-if="showUserRoleSelect"
        :options="roleOptions"
        :label="t('cms_browse_as')"
        :selected-role="selectedRole"
        class="container justify-between bg-white py-2.5 lg:hidden"
        @change-option="changeOption"
      />
    </template>
  </UiPageHeader>
</template>

<script lang="ts" setup>
import type { T3InitialData } from '@t3headless/nuxt-typo3'
import type { UiNavLink } from '@ui/components/UiNav/UiNav.types'
import UiUserRoleSelect from '@ui/components/UiUserRoleSelect/UiUserRoleSelect.vue'
import type { RouteLocationRaw } from 'vue-router'
import { useCustomer } from '@customer/composables/useCustomer'
import type { UserRoleOption } from '@ui/components/UiUserRoleSelect/UiUserRoleSelect.types'
import isExternalLink from '@ui/utils/isExternalLink'
import { useDrawer } from '../../components/AppDrawer/useDrawer'
import { useUiState } from '../../composables/useUiState'
import { useAppNav } from '../../components/AppNav/useAppNav'
import useConditionalRender from '../../composables/useConditionalRender/useConditionalRender'
import { useRegionalCookie } from '../../composables/useRegionalStorage'
import type { AppHeader } from './AppHeader.types'

interface AppHeaderProps {
  initialData: T3InitialData
}
const props = defineProps<AppHeaderProps>()

const { navigation } = useAppNav()

const { toggleAppNav, toggleAppRegions } = useDrawer()
const { isEcommerceEnabled } = useConditionalRender()
const { runWithContext } = useNuxtApp()
const { localePath } = useT3Utils()
const { t } = useI18n()
const { isLoggedIn } = useCustomer()
const route = useRoute()

const searchPhraseValue = ref('')
if (isEcommerceEnabled.value) {
  await import('../../../rawlplug-ecom/components/AppSearch/useAppSearchState').then(({ useAppSearchState }) => {
    runWithContext(() => {
      const { searchPhrase } = useAppSearchState()
      searchPhraseValue.value = searchPhrase.value
    })
  })
}

/**
 * Set header state (height and isSticky)
 */
const { state, component } = useUiState()
const header = component<AppHeader>('header')

function setHeaderState<K extends keyof AppHeader>(
  type: K,
  value: AppHeader[K],
) {
  state.value.header[type] = value
}

async function handleNavigateTo(link: UiNavLink, isExternal = false) {
  if (isExternal) {
    await navigateTo(link.link as RouteLocationRaw, {
      external: true,
      open: {
        target: '_blank',
      },
    })
  }

  await navigateTo(link.link as RouteLocationRaw)
}

const showUserRoleSelect = computed(() => {
  if (isLoggedIn.value) {
    return false
  }
  // check if current page is one of the available variants
  const homePageVariants = props.initialData?.variants?.items
  if (Array.isArray(homePageVariants)) {
    return homePageVariants?.some(variant => variant.url === route.path)
  }
  return false
})

const roleOptions = computed<UserRoleOption[]>(() => {
  if (!props.initialData.variants?.items?.length) { return [] }

  return props.initialData.variants?.items?.map(option => ({
    label: option.label,
    value: {
      url: option.url,
      uid: option.uid,
    },
  }))
})

const expires = new Date()
expires.setTime(expires.getTime() + 30 * 24 * 60 * 60 * 1000)

const homepageVariantCookie = useRegionalCookie<number>(props.initialData.variants?.cookie, {
  expires,
  secure: true,
})

const selectedRole = computed({
  get() {
    const options = roleOptions.value
    const equalPage = roleOptions.value?.find(role => route.path === role.value?.url)

    if (!options || !options.length) { return undefined }

    if (equalPage?.label) {
      return equalPage
    }
    else if (homepageVariantCookie.value) {
      return options?.find(role => role.value?.uid === homepageVariantCookie.value)
    }
    return options?.[0]
  },

  set(newValue) {
    return newValue
  },
})

async function changeOption(option: typeof roleOptions['value'][number]) {
  if (isExternalLink(option.value.url)) {
    await navigateTo(option.value.url, {
      external: isExternalLink(option.value.url),
      open: {
        target: '_blank',
      },
    })
  }
  else {
    homepageVariantCookie.value = option.value.uid
    selectedRole.value = option
    await navigateTo(option.value.url)
  }
}
</script>
